.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.login-wrap{
  background-image: url(./img/fondo.jpg);
  /*background-color: #562483;*/
  background-size: cover;
  height: 100vh;
    width: 100vw;
}

#login-codigo {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: white;
  border-bottom: 1px solid #ffffff;
  border-radius: 0px;
  padding-left: 0pt;
  box-sizing: content-box;
  width: 100%;
  font-size: 14pt;
}

#login-codigo::placeholder {
  color: white;
}

.title-login {
  margin-top: 20%;
  color: white;
  font-family: Arial;
  font-weight: bold;
}

.title-login-footer {
  margin-top: 80%;
  color: white;
  font-family: Arial;
  font-weight: bold;
}

.title-home{
  font-size: 22px;
  font-weight: bold;
  font-family: Arial;
  margin-bottom:0;
}

.fecha-home{
  font-size: 15px;
  font-weight: bold;
  font-family: Arial;
  margin-bottom:0;
}

.card-body {
  padding: 0.25rem!important;
}

.table-ingreso td, .table th {
  padding: 0rem!important;
}

.table-ingreso{
  margin-bottom: 0rem!important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-inverse {
  background-color: #380ab4 !important;
}

.gradiente-www {
    height: 100vh;
    width: 100vw;
    background: #562483;
    background: -moz-linear-gradient(-45deg, #562483 38%, #e54630 100%);
    background: -webkit-linear-gradient(
  -45deg
  , #562483 38%,#e54630 100%);
    background: linear-gradient(
  135deg
  , #562483 38%,#e54630 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#562483', endColorstr='#e54630',GradientType=1 );
}

.gradiente-www h4{
  color:#fff;
}

.tituloSuperior {
  background-color: #f60;
  color: white;
  font-size: 16px;
  font-weight: 500;
  height: 26px;
}

.btn-blanco {
  border-color: #e5402d!important;;
  color: #e5402d!important;
  font-weight: bold;
  padding-top: 8px;
}

.btn-blanco:hover {
  background-color: #e5402d;
  color: #fff!important;
}

.btn-naranja {
  background-color: #f60!important;
  border-color: #f60!important;
  color: #fff!important;
}

.btn-naranja:hover {
  background-color: #d33523!important;;
}


.btn-lila {
  background-color: #562483!important;
}

.btn-lila:hover {
  background-color: #843cc4!important;
}
.btn-ew {
	color: white;
	border-radius: 15pt!important;
  font-size: 18px!important;
}

.btn-ew:focus{
  outline: 0px auto -webkit-focus-ring-color !important;
}

.logout-link {
  color: white;
}

.btn-close {
  color: #aaa;
  font-size: 30px;
  text-decoration: none;
  position: absolute;
  right: 5px;
  top: 0;
}
.btn-close:hover {
  color: #919191;
}

.modal-install:target:before {
  display: block;
}
.modal-install:target .modal-install-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  top: 20%;
}
.modal-install-dialog {
  background: #fefefe;
  border: #333 solid 1px;
  border-radius: 5px;
  position: fixed;
  margin: 0 auto;
  z-index: 11;
  -webkit-transform: translate(0, -230%); 
  -ms-transform: translate(0, -230%);
  transform: translate(0, -230%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  left: 0;
  right: 0;
}
.modal-install-body {
  padding: 20px;
}
.modal-install-header,
.modal-install-footer {
  padding: 10px 20px;
}
.modal-install-header {
  border-bottom: #eee solid 1px;
}
.modal-install-header h2 {
  font-size: 20px;
}
.modal-install-footer {
  border-top: #eee solid 1px;
  text-align: right;
}
.td-text {
  font-size: 1.1rem;
}